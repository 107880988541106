
import { Component, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Partner, PartnerService, PartnerType } from "@/store/modules/partner.store";
import { formatDate } from "@/utils/formatters";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
        UserImage: () => import("@/components/general/user-image.vue"),
    },
})
export default class PageAdminPartnersIndex extends Vue {
    @Getter("auth/me") me!: CurrentAccount;
    @Getter("partner/all") partners!: Partner[];

    @Action("partner/index") indexPartners!: (payload: any) => Promise<Partner[]>;

    partnerTypes = PartnerType;

    loaded: boolean = false;

    formatDate = formatDate;

    async mounted() {
        await this.indexPartners({ per_page: 99 });

        this.loaded = true;
    }

    getLabelForService(service: PartnerService) {
        if (!service.addons || service.addons.length === 0) {
            return service.title;
        }

        if (service.addons.length === 1) {
            return `${service.title} - ${service.addons[0].title}`;
        }

        return `${service.title} - (${service.addons.map((t) => t.title).join(" & ")}) `;
    }
}
